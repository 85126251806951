@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  margin-bottom: 35px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* gilroy-800 - latin */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('https://www.fixje.nl/wp-content/themes/fixjeiphone3.0/assets/fonts/gilroy-extrabold.eot'); /* IE9 Compat Modes */
  src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
  url('https://www.fixje.nl/wp-content/themes/fixjeiphone3.0/assets/fonts/gilroy-extrabold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://www.fixje.nl/wp-content/themes/fixjeiphone3.0/assets/fonts/gilroy-extrabold.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://www.fixje.nl/wp-content/themes/fixjeiphone3.0/assets/fonts/gilroy-extrabold.woff') format('woff'), /* Modern Browsers */
  url('https://www.fixje.nl/wp-content/themes/fixjeiphone3.0/assets/fonts/gilroy-extrabold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://www.fixje.nl/wp-content/themes/fixjeiphone3.0/assets/fonts/gilroy-extrabold.svg#gilroyextrabold') format('svg'); /* Legacy iOS */
}
.root {
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  cursor: pointer;
}

.step .Mui-disabled .MuiSvgIcon-root {
  background-color: #e7e7e7;
}
/* 
.stepLabel.Mui-disabled .MuiStepIcon-root {
  background-color: #e7e7e7 !im;
} */

.stepBlocked  {
  background-color: #e7e7e7;
}

.step .Mui-disabled .MuiSvgIcon-root path{
  display: none;
}
.pageTitle {
  font-size: 32px !important;
  font-weight: 900 !important;
}

.display{
  display: unset;
}

.hide{
  display: none;
}
.header {
  padding: 11px 0;
  background: linear-gradient(#049eea, #037FCC);
}

.header img {
  max-height: 50px;
}

@media screen and (max-width: 450px) {
  .header img {
    max-width: 180px;
  }
} 

.header-links {
  display: flex;
  justify-content: flex-end;
}

.header-img-wrapper {
  align-items: center;
  display: flex;
}

.header-links p {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  cursor: pointer;
}

.header-links a {
  text-decoration: none !important;
}

.header-links p:first-child {
  font-weight: 700;
}

.header-links p:last-child {
  font-weight: 300;
}

.loadingBar {
  margin-top: -4px;
}

.headerBack {
  font-weight: 700;
}

.headerBack:hover {
  text-decoration: underline !important;
}
.pageTitle {
  font-size: 32px !important;
  font-weight: 900 !important;
}

.backText {
  margin-bottom: 15px !important;
  display: block !important;
}

.pagebody {
  margin-bottom: 20px !important;
}
.root {
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.stepLabel svg {
  padding: 12px;
  border-radius: 50%;
  background-color: #049eea;
  color: #fff;
  z-index: 1 !important;
}
.textfield {
  width: 100%;
  margin-bottom: 15px !important;
}

.confirmButton {
  padding: 15px 10px !important;
  color: #fff !important;
  background-color: #00b900 !important;
  font-weight: 700 !important;
  border: 1px solid #009900 !important;
}

.captchaError {
  color: #f44336;
  margin-left: 14px !important;
  margin-right: 14px !important;
  font-size: 12px !important;
  margin-top: 3px !important;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66 !important;
  letter-spacing: 0.03333em !important;
}

.alertBox {
  margin-bottom: 20px;;
}
.productPaper {
  padding: 25px;
  border-radius: 6px;
  border: solid 1px #e7e7e7;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .productPaper {
    padding: 10px 10px 10px 5px;
    border-radius: 3px;
    margin-top: 10;
    margin-bottom: 10px;
  }
}

.productPaper.checked {
  border-color: #049eea;
}

.image {
  width: 80px;
  height: 80px;
}

.mobileProductTitle {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.mobileProductTitle p {
  margin-bottom: 0 !important;
}

.desktopProductTitle {
  display: none;
}

@media screen and (min-width: 992px) {
  .mobileProductTitle {
    display: none !important;
  }

  .desktopProductTitle {
    display: block;
  }
}

.productName {
  margin-left: 20px;
}
.productGrid{
  min-height: 75px;
}
.productTitle{
  font-weight: 700!important;
  margin-bottom: 10px !important;
}

.gridPaper {
  text-align: center;
}

.warrantyNotice {
  color: #ff0000;
}

.imeiTitlesWrapper {
  display: flex;
  justify-content: space-between;
}

.imeiExplain {
  margin-top: 4px !important;
  font-size: 12px !important;
}
.headText {
  font-weight: 700!important;
}

.confirmButton:disabled {
  opacity: 0.5;
}
.pageTitle {
  font-size: 32px !important;
  font-weight: 900 !important;
}

.backText{
  color: #049eea;
  cursor: pointer;
  text-decoration: underline;
}

.returnReasonBlock {
  padding: 25px 10px;
  border-radius: 6px;
  border: solid 1px #e7e7e7;
  margin-top: 20px;
  -webkit-user-select: none;
          user-select: none;
  height: auto;
}

.returnDataDropdown{
  margin-bottom: 5px!important;
  width: 100%;
}

.helperTextError,
.red {
  color: #ff0000 !important;
}

.returnReasonTitle{
  margin-top: 10px!important;
}

.alertTryThis {
  padding: 22px;
  border-radius: 6px;
  border: solid 1px #d2dfed!important;
  background-color: #eef5fc!important;
  margin-bottom: 30px!important;
}

.alertText {
  font-weight: 700!important;
}

.alertAction{
  color: #049eea;
  cursor: pointer;
  text-decoration: underline;
}

.alertSecondary{
  font-weight: 400!important;
}

.confirmButton {
  margin-top: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.pageTitle {
  font-size: 32px !important;
  font-weight: 900 !important;
}

.confirmButton.sendItemBtn {
  border: 1px solid #037fcc !important;
}
.pageTitle {
  font-size: 32px !important;
  font-weight: 900 !important;
}

.sendTitle{
  font-size: 22px !important;
  font-weight: 900 !important;
}


.textCircle{
  width: 47px;
  height: 48px; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#049eea;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-right: 18px;
}

.todoStep{
  font-weight: bold!important;
}

.sendItemBtn{
  background-color: #049eea!important;
  margin-right: 20px!important;
}

.adresRetour{
  font-weight: 900!important;
  font-size: 18px!important;
}

.stepsToRetour{
  margin-top: 30px;
}

.stepDescr {
  margin-top: 10px !important;
}

.box-title {
  display: flex;
  align-items: center;
}

.box-title p {
  margin-bottom: 0 !important;
}

.retourAdress{
  background-color: #ebf5fd;
  padding: 20px;
  border-radius: 4px;
}

.sendProduct{
  margin-top: 40px;
}

.lastRow{
  margin-top: 15px!important;
}

.finishPage{
  margin-bottom: 40px;
}
