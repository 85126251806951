.pageTitle {
  font-size: 32px !important;
  font-weight: 900 !important;
}

.sendTitle{
  font-size: 22px !important;
  font-weight: 900 !important;
}


.textCircle{
  width: 47px;
  height: 48px; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#049eea;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-right: 18px;
}

.todoStep{
  font-weight: bold!important;
}

.sendItemBtn{
  background-color: #049eea!important;
  margin-right: 20px!important;
}

.adresRetour{
  font-weight: 900!important;
  font-size: 18px!important;
}

.stepsToRetour{
  margin-top: 30px;
}

.stepDescr {
  margin-top: 10px !important;
}

.box-title {
  display: flex;
  align-items: center;
}

.box-title p {
  margin-bottom: 0 !important;
}

.retourAdress{
  background-color: #ebf5fd;
  padding: 20px;
  border-radius: 4px;
}

.sendProduct{
  margin-top: 40px;
}

.lastRow{
  margin-top: 15px!important;
}

.finishPage{
  margin-bottom: 40px;
}